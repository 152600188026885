import { useStore } from "vuex";
import { useRouter } from "vue-router";

function useProcedureRoutes() {
  const store = useStore();
  const router = useRouter();

  const procedurePatientRoute = () => {
    const query: any = {};
    Object.keys(store.state.procedure.form)
      .map((cd_estabelecimento) => Number(cd_estabelecimento))
      .forEach((cd_estabelecimento) => {
        query[`et${cd_estabelecimento}`] = [
          ...store.state.procedure.form[cd_estabelecimento].cd_procedimento.map((_id) => `pe${_id}`),
        ];
      });

    return { name: "procedure-confirm", query, params: { noScroll: "true" } };
  };

  function goToProcedurePatient() {
    const routeTo = { ...procedurePatientRoute() };
    router.push(routeTo);
  }

  return {
    goToProcedurePatient,
    procedurePatientRoute,
  };
}

export { useProcedureRoutes };
